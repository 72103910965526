import { LogParams } from 'types/getParams';
import { CRMAPIBase } from '../crmApiBase';
import { CRMListResp } from '../responseModels/crm/crmListResponse';
import { CRMResp } from '../responseModels/crm/crmResponse';
import { CRMTotalResp } from '../responseModels/crm/crmTotalResponse';
import { RequestResult } from '../responseModels/requestResult';

class CRMGroup extends CRMAPIBase {
    /**
     * Добавление CRM
     * @param name Название
     * @param owner_id ID создателя
     * @param description Описание
     * @returns Результат запроса
     */
    async saveCRM(
        name: string,
        owner_id: number,
        description?: string
    ): Promise<RequestResult<CRMResp>> {
        const result = await this.post<CRMResp>('/crms', {
            organization_name: name,
            owner_user_id: owner_id,
            description: description,
        });
        return result;
    }

    /**
     * Получение CRM
     * @param crmId ID CRM
     * @returns Результат запроса
     */
    async getCRM(crmId: number): Promise<RequestResult<CRMResp>> {
        const result = await this.get<CRMResp>(`/crms/${crmId}`, { crm_id: crmId });
        return result;
    }

    /**
     * Получение всех CRM
     * @returns Результат запроса
     */
    async getCRMList(is_worker: boolean): Promise<RequestResult<CRMListResp>> {
        const result = await this.get<CRMListResp>('/crms', {is_worker: is_worker});
        return result;
    }

    /**
     * Обновление CRM
     * @param crmId ID CRM
     * @param organizationName Название
     * @param description Описание
     * @param address Адрес
     * @returns Результат запроса
     */
    async updateCRM(
        crmId: number,
        organizationName: string,
        description: string,
        address: string
    ): Promise<RequestResult<CRMResp>> {
        const result = await this.put<CRMResp>(`/crms/${crmId}`, {
            organization_name: organizationName,
            description: description,
            address: address,
            crm_id: crmId,
        });
        return result;
    }

    /**
     * Удаление CRM
     * @param crmId ID CRM
     * @returns Результат запроса
     */
    async removeCRM(crmId: number): Promise<RequestResult<CRMResp>> {
        const result = await this.delete<CRMResp>(`/crms/${crmId}`, { crm_id: crmId });
        return result;
    }

    /**
     * @deprecated
     * Поиск CRM
     * @param organizationName Название CRM
     * @returns Результат запроса
     */
    async searchCRM(organizationName: string): Promise<RequestResult<CRMListResp>> {
        const result = await this.get<CRMListResp>('/crm/search', {
            query: 'organization_name=' + organizationName,
        });
        return result;
    }

    /**
     * Статистика CRM
     * @param crmId ID CRM
     * @returns Результат запроса
     */
    async getCRMTotal(crmId: number): Promise<RequestResult<CRMTotalResp>> {
        const result = await this.get<CRMTotalResp>(`/crms/${crmId}/total`, { crm_id: crmId });
        return result;
    }

    /**
     * Получение списка логов CRM
     * @param crmId ID CRM
     * @param subject_type Тип объекта
     * @param filters.created_at Период создания
     * @param page Страница
     * @param per_page Записей на страницу
     * @returns Результат запроса
     */
    async getLogList(params: LogParams): Promise<RequestResult<any>> {
        const result = await this.get<any>('/logs', { ...params });
        return result;
    }
}

export { CRMGroup };
