import { find, includes, isEmpty, map, without } from 'lodash';

import { getSelectedMessagesString } from '@helpers/getSelectedMessagesString';
import { createSlice } from '@reduxjs/toolkit';

import { initialComment, replyCommentInitialData } from '../constants';

const commentSlice = createSlice({
    name: 'comment',
    initialState: {
        data: [initialComment],
        messages: [],
        messagesMeta: null,
        currentUser: null,
        selectedComments: [],
        selectedFiles: [],
        copiedSuccess: true,
        selectedRecipients: [],
        isEditMode: false,
        isReplyMode: false,
        replyCommentData: replyCommentInitialData,
        filterData: {
            topics: [],
            recipients: [],
            files: [],
            start_date: null,
            end_date: null,
            searchText: '',
        },
    },
    reducers: {
        addMessage: (state, { payload }) => {
            state.messages = payload;
        },

        setMessagesMeta: (state, { payload }) => {
            state.messagesMeta = payload;
        },

        selectComment: (state, { payload }) => {
            if (!includes(state.selectedComments, payload)) {
                state.selectedComments.push(payload);
                return;
            }
            state.selectedComments = without(state.selectedComments, payload);
            state.isEditMode = false;
        },

        selectFile: (state, { payload }) => {
            if (!includes(state.selectedFiles, payload)) {
                state.selectedFiles.push(payload);
                return;
            }
            state.selectedFiles = without(state.selectedFiles, payload);
        },

        deleteMessage: (state, { payload }) => {
            state.messages = state.messages.filter((comment) => comment.id !== payload);
            state.selectedComments = state.selectedComments.filter((id) => id !== payload);
        },

        copyToClipboard: (state) => {
            // const text = getSelectedMessagesString(state.data, state.selectedComments);
            const text = 'test'


            try {
                navigator.clipboard.writeText(text);
                state.copiedSuccess = true;
                state.selectedComments = [];
            } catch {
                state.copiedSuccess = false;
            }
        },

        selectRecipients: (state, { payload }) => {
            state.selectedRecipients = payload;
        },

        toggleEdit: (state, { payload }) => {
            state.isEditMode = payload;
        },

        toggleReply: (state, { payload }) => {
            state.isReplyMode = payload;
            if (!payload) {
                state.replyCommentData = replyCommentInitialData;
                return;
            }

            const {
                worker_position: recipient,
                message,
                topic,
                id,
            } = find(state.messages, (mess) => mess.id === state.selectedComments[0]);

            state.replyCommentData = {
                message,
                recipient,
                topic,
                messageId: id,
            };
            state.selectedComments = [];
        },

        clearSelectedComments: (state) => {
            state.selectedComments = [];
        },

        setFilterArrayItem: (state, { payload }) => {
            const { item, value } = payload;

            const selectedIds = map(state.filterData[item], (elem) => elem.id);

            if (!includes(selectedIds, value.id)) {
                state.filterData[item].push(value);
                return;
            }
            state.filterData[item] = without(selectedIds, value.id);
        },

        resetFilterArrayItem: (state, { payload }) => {
            state.filterData[payload] = [];
        },

        setFilterFiles: (state, { payload }) => {
            if (!includes(state.filterData.files, payload)) {
                state.filterData.files.push(payload);
                return;
            }

            state.filterData.files = without(state.filterData.files, payload);
        },

        setFilterDate: (state, { payload }) => {
            state.filterData[payload.key] = payload.value;
        },

        setFilterAllFiles: (state, { payload }) => {
            if (isEmpty(state.filterData.files)) {
                state.filterData.files = payload;
                return;
            }

            state.filterData.files = [];
        },

        setSearchText: (state, { payload }) => {
            state.filterData.searchText = payload;
        },

        setCurrentUser: (state, { payload }) => {
            state.currentUser = payload;
        },
    },
});

export const {
    selectComment,
    selectFile,
    clearSelectedComments,
    copyToClipboard,
    selectRecipients,
    toggleEdit,
    toggleReply,
    setFilterArrayItem,
    resetFilterArrayItem,
    setFilterFiles,
    setSearchText,
    setFilterAllFiles,
    setCurrentUser,
    addMessage,
    setMessagesMeta,
    deleteMessage,
    setFilterDate,
} = commentSlice.actions;
export default commentSlice.reducer;
