import { WorkerPositionListResp } from '@api/responseModels/worker/workerPositionListResponse';

import { TWorkerPosition } from '../../types/worker';
import { CRMAPIBase } from '../crmApiBase';
import { RequestResult } from '../responseModels/requestResult';
import { WorkerPositionResp } from '../responseModels/worker/workerPositionResponse';

class WorkerPositionGroup extends CRMAPIBase {
    /**
     * Добавление рабочего места
     * @param crmId ID CRM
     * @param workerPosition Рабочее место
     * @returns Результат запроса
     */
    async saveWorkerPosition(
        crmId: number,
        workerPosition: TWorkerPosition
    ): Promise<RequestResult<WorkerPositionResp>> {
        const result = await this.post<WorkerPositionResp>('/worker-positions', {
            crm_id: crmId,
            ...workerPosition,
        });
        return result;
    }

    /**
     * Получение рабочего места
     * @param id ID сотрудника
     * @param crmId ID CRM
     * @returns Результат запроса
     */
    async getWorkerPosition(id: number, crmId: number): Promise<RequestResult<WorkerPositionResp>> {
        const result = await this.get<WorkerPositionResp>(`/worker-positions/${id}`, {
            crm_id: crmId,
        });
        return result;
    }

    /**
     * Обновление рабочего места
     * @param crmId ID CRM
     * @param workerPosition Рабочее место
     * @returns Результат запроса
     */
    async updateWorkerPosition(
        crmId: number,
        workerPosition: TWorkerPosition
    ): Promise<RequestResult<WorkerPositionResp>> {
        const result = await this.put<WorkerPositionResp>(
            `/worker-positions/${workerPosition.id}`,
            {
                crm_id: crmId,
                ...workerPosition,
            }
        );
        return result;
    }

    /**
     * Удаление рабочего места
     * @param id ID рабочего места
     * @param crmId ID CRM
     * @returns Результат запроса
     */
    async removeWorkerPosition(
        id: number,
        crmId: number
    ): Promise<RequestResult<WorkerPositionResp>> {
        const result = await this.delete<WorkerPositionResp>(`/worker-positions/${id}`, {
            crm_id: crmId,
        });
        return result;
    }

    /**
     * Восстановление рабочего места
     * @param id ID рабочего места
     * @param crmId ID CRM
     * @returns Результат запроса
     */
    async restoreWorkerPosition(
        id: number,
        crmId: number
    ): Promise<RequestResult<WorkerPositionResp>> {
        const result = await this.post<WorkerPositionResp>(`/worker-positions/${id}/restore`, {
            crm_id: crmId,
        });
        return result;
    }

    /**
     * Получение списка рабочих мест
     * @param crmId ID CRM
     * @returns Результат запроса
     */
    async getWorkerPositionList(params: any): Promise<RequestResult<WorkerPositionListResp>> {
        const result = await this.get<WorkerPositionListResp>(`/worker-positions`, params);
        return result;
    }
}

export { WorkerPositionGroup };
