import { CRMAPIBase } from '@api/crmApiBase';
import { ChatMessageTopicResp } from '@api/responseModels/chatMessages/chatMessageTopicResponse';
import { ChatMessageTopicsResp } from '@api/responseModels/chatMessages/chatMessageTopicsResponse';
import { RequestResult } from '@api/responseModels/requestResult';

class ChatMessageTopicGroup extends CRMAPIBase {
    async getChatMessageTopics(
        crm_id: number,
        page: number
    ): Promise<RequestResult<ChatMessageTopicsResp>> {
        const response = await this.get<ChatMessageTopicsResp>('/chat-message-topics', {
            crm_id,
            page,
        });
        return response;
    }

    async createChatMessageTopic(
        crm_id: number,
        name: string
    ): Promise<RequestResult<ChatMessageTopicResp>> {
        const result = await this.post<ChatMessageTopicResp>(`/chat-message-topics`, {
            crm_id,
            name,
        });
        return result;
    }
}

export { ChatMessageTopicGroup };
