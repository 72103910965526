import { GlobalConstants } from "@constants/global";
import { CookieManager } from "./cookieManager";
import { rootStore } from "@store/rootStore/instanse";

type TStyles = {
    backgroundId: number;
};

class StylesManager {
    static setStyles(styles: TStyles) {
        var now = new Date();
        var time = now.getTime();
        var expireTime = time + 1000*60*60*24*365;
        now.setTime(expireTime);
        CookieManager.setCookie(GlobalConstants.StyleSettingsProperty, JSON.stringify(styles), { expires: now.toUTCString()});
    }

    static getStyles(): TStyles {
        let styles: TStyles = null;
        const value = CookieManager.getCookie(GlobalConstants.StyleSettingsProperty);
        if (value) {
            styles = JSON.parse(value)
        }
        return styles;
    }

    static updateStyles(styles: TStyles) {
        const oldStyles = this.getStyles();
        this.setStyles(!oldStyles ? styles : {...oldStyles, ...styles});
        rootStore.currentUserStore.updateStylesTimestamp();
    }

    static clearStyles() {
        this.setStyles({
            backgroundId: 1,
        })
    }
}

export { TStyles, StylesManager };