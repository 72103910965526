import { createSlice } from '@reduxjs/toolkit';

const topicsSlice = createSlice({
    name: 'topics',
    initialState: {
        data: [],
    },
    reducers: {
        setTopics: (state, { payload }) => {
            state.data = payload;
        },

        resetTopics: (state) => {
            state.data = [];
        },
    },
});

export const { setTopics, resetTopics } = topicsSlice.actions;

export const topicsReducer = topicsSlice.reducer;
