import { Suspense, lazy, useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { Loader } from '@atoms/loader';
import { messageService } from '@classes/messageService';
import { SettingsManager } from '@classes/settingsManager';
import { TaskUseCases } from '@components/tasks/profile/taskProfile';
import { ApelsinFooter } from '@organisms/common/apelsinFooter';
import { ApelsinHeader } from '@organisms/common/apelsinHeader';
import { rootStore } from '@store/rootStore/instanse';
import { StylesData } from '@constants/stylesData';
import { observer } from 'mobx-react';

const TaskCategoryList = lazy(() => import('@components/tasks/category/taskCategoryList'));
const TaskCategoryProfile = lazy(() => import('@components/tasks/category/taskCategoryProfile'));
const TaskProfile = lazy(() => import('@components/tasks/profile/taskProfile'));
const TaskList = lazy(() => import('@components/tasks/taskList'));
const WorkerSchedule = lazy(() => import('@components/workers/schedule/schedule'));
const Administrator = lazy(() => import('@organisms/administrator/administrator'));
const Section = lazy(() => import('@organisms/administrator/partials/section/section'));
const CRMProfile = lazy(() => import('@organisms/crmProfile'));
const CustomerList = lazy(() => import('@organisms/customers/customerList'));
const CustomerProfile = lazy(() => import('@organisms/customers/customerProfile'));
const InvitationList = lazy(() => import('@organisms/invitations/invitationList'));
const OrderList = lazy(() => import('@organisms/orders/orderList'));
const OrderCreate = lazy(() => import('@organisms/orders/profile/orderCreate'));
const OrderProfile = lazy(() => import('@organisms/orders/profile/orderProfile'));
const PermissionSettings = lazy(() => import('@organisms/permissionSettings/permissionSettings'));
const ProductList = lazy(() => import('@organisms/product/productList'));
const ProductProfile = lazy(() => import('@organisms/product/productProfile'));
const ProductCategoryList = lazy(() => import('@organisms/productCategory/productCategoryList'));
const ProductCategoryProfile = lazy(
    () => import('@organisms/productCategory/productCategoryProfile')
);
const ConnectionForm = lazy(() => import('@organisms/security/connectionForm'));
const LogList = lazy(() => import('@organisms/security/logList'));
const Logout = lazy(() => import('@organisms/security/logout'));
const TransactionList = lazy(() => import('@organisms/security/transactionList'));
const ServiceList = lazy(() => import('@organisms/service/serviceList'));
const ServiceProfile = lazy(() => import('@organisms/service/serviceProfile'));
const ServiceCategoryList = lazy(() => import('@organisms/serviceCategory/serviceCategoryList'));
const ServiceCategoryProfile = lazy(
    () => import('@organisms/serviceCategory/serviceCategoryProfile')
);
const CustomizableSection = lazy(
    () => import('@organisms/settings/customizableSection/customizableSection')
);
const Settings = lazy(() => import('@organisms/settings/settings'));
const WorksheetSetting = lazy(
    () => import('@organisms/settings/worksheetSetting/worksheetSetting')
);
const OrderStatusSetting = lazy(() => import('@organisms/settings/orderStatusSetting/orderStatusSetting'));
const StyleSetting = lazy(() => import('@organisms/settings/styleSetting/styleSetting'));
const Worksheets = lazy(() => import('@organisms/settings/worksheets/worksheets'));
const SpecialtyList = lazy(() => import('@organisms/specialty/specialtyList'));
const SpecialtyProfile = lazy(() => import('@organisms/specialty/specialtyProfile'));
const WorkerList = lazy(() => import('@organisms/workers/workerList'));
const WorkerProfile = lazy(() => import('@organisms/workers/workerProfile'));
const WorkplaceProfile = lazy(() => import('@organisms/workers/workplaceProfile/workplaceProfile'));
const UserProfile = lazy(() => import('@organisms/users/userProfile'));
const WorkerProfile_Old = lazy(() => import('@organisms/workers/workerProfile_old'));

const App = observer((): JSX.Element => {
    const navigate = useNavigate();
    const location = useLocation();
    const [curStyles, setCurStyles] = useState(StylesData.getBackgroundStyles());

    function checkConnection() {
        //console.log(`checkConnection: token?=${SettingsManager.getConnectionCredentials()?.token != null}, crmID?=${SettingsManager.getConnectionCredentials()?.crmID}`);
        if (SettingsManager.getConnectionCredentials()?.token != null) {
            if (SettingsManager.getConnectionCredentials().crmID && location?.pathname == '/') {
                navigate(`/lk/worker/crm/${SettingsManager.getConnectionCredentials().crmID}`);
            } else if (location?.pathname == '/') navigate('/lk');
        } else {
            if (location?.pathname != 'login') navigate('/login');
        }
    }

    useEffect(() => {
        checkConnection();
        rootStore.navigationStore.pushLastPath(location.pathname);
        messageService.clearMessages();
    }, [location.pathname]);

    useEffect(() => {
        setCurStyles(StylesData.getBackgroundStyles());
    }, [rootStore.currentUserStore.stylesTimestamp]);

    function getBody(): JSX.Element {
        return (
            <Suspense fallback={<Loader />}>
                <Routes>
                    <Route path="/login" element={<ConnectionForm />} />
                    <Route path="/lk" element={<UserProfile />} />
                    <Route path="/logout" element={<Logout />} />
                    <Route path="/lk/worker/crm/:id" element={<CRMProfile />} />
                    <Route path="/lk/worker/crm/:id/edit" element={<CRMProfile />} />
                    <Route path="/lk/worker/crm/:id/invitations" element={<InvitationList />} />
                    <Route
                        path="/lk/worker/crm/:id/workers/tabs/:currentTab"
                        element={<WorkerList />}
                    />
                    <Route
                        path="/lk/worker/crm/:id/workers/tabs/:currentTab/:groupId/workplaces"
                        element={<WorkerList />}
                    />
                    {/* Создание рабочего места */}
                    <Route
                        path="/lk/worker/crm/:id/workers/tabs/:currentTab/:groupId/workplaces/create"
                        element={<WorkplaceProfile />}
                    />
                    {/* Редактирование рабочего места */}
                    <Route
                        path="/lk/worker/crm/:id/workers/tabs/:currentTab/:groupId/workplaces/:workplaceId"
                        element={<WorkplaceProfile />}
                    />
                    <Route path="/lk/worker/profile" element={<WorkerProfile />} />
                    <Route path="/lk/worker/profile/edit" element={<WorkerProfile />} />
                    <Route
                        path="/lk/worker/crm/:crmID/workers/:workerID"
                        element={<WorkerProfile />}
                    />
                    <Route
                        path="/lk/worker/crm/:crmID/workers/create"
                        element={<WorkerProfile_Old />}
                    />
                    <Route
                        path="/lk/worker/crm/:crmID/workers/:workerID/edit"
                        element={<WorkerProfile />}
                    />
                    <Route path="/lk/worker/crm/:crmID/users/:workerID" element={<UserProfile />} />
                    <Route
                        path="/lk/worker/crm/:crmID/workers/:workerID/schedule"
                        element={<WorkerSchedule />}
                    />
                    <Route
                        path="/lk/worker/crm/:crmID/workers/:workerID/permissions"
                        element={<PermissionSettings />}
                    />
                    <Route path="/lk/worker/crm/:crmID/customers" element={<CustomerList />} />
                    <Route
                        path="/lk/worker/crm/:crmID/customers/:customerID"
                        element={<CustomerProfile />}
                    />
                    <Route
                        path="/lk/worker/crm/:crmID/customers/create"
                        element={<CustomerProfile />}
                    />
                    <Route
                        path="/lk/worker/crm/:crmID/customers/:customerID/edit"
                        element={<CustomerProfile />}
                    />
                    <Route path="/lk/worker/crm/:id/specialties" element={<SpecialtyList />} />
                    <Route
                        path="/lk/worker/crm/:id/specialties/:specialtyID"
                        element={<SpecialtyProfile />}
                    />
                    <Route
                        path="/lk/worker/crm/:id/specialties/:specialtyID/edit"
                        element={<SpecialtyProfile />}
                    />
                    <Route
                        path="/lk/worker/crm/:id/product-category"
                        element={<ProductCategoryList />}
                    />
                    <Route
                        path="/lk/worker/crm/:id/product-category/:productCategoryID"
                        element={<ProductCategoryProfile />}
                    />
                    <Route
                        path="/lk/worker/crm/:id/product-category/create"
                        element={<ProductCategoryProfile />}
                    />
                    <Route
                        path="/lk/worker/crm/:id/product-category/:productCategoryID/edit"
                        element={<ProductCategoryProfile />}
                    />
                    <Route path="/lk/worker/crm/:id/products" element={<ProductList />} />
                    <Route
                        path="/lk/worker/crm/:id/products/nav/:productCategoryId"
                        element={<ProductList />}
                    />
                    <Route
                        path="/lk/worker/crm/:id/products/:productID"
                        element={<ProductProfile />}
                    />
                    <Route path="/lk/worker/crm/:id/products/create" element={<ProductProfile />} />
                    <Route
                        path="/lk/worker/crm/:id/products/:productID/edit"
                        element={<ProductProfile />}
                    />
                    <Route
                        path="/lk/worker/crm/:id/service-categories"
                        element={<ServiceCategoryList />}
                    />
                    <Route
                        path="/lk/worker/crm/:id/service-categories/:serviceCategoryID"
                        element={<ServiceCategoryProfile />}
                    />
                    <Route
                        path="/lk/worker/crm/:id/service-categories/create"
                        element={<ServiceCategoryProfile />}
                    />
                    <Route
                        path="/lk/worker/crm/:id/service-categories/:serviceCategoryID/edit"
                        element={<ServiceCategoryProfile />}
                    />
                    <Route path="/lk/worker/crm/:id/services" element={<ServiceList />} />
                    <Route
                        path="/lk/worker/crm/:id/services/nav/:serviceCategoryId"
                        element={<ServiceList />}
                    />
                    <Route
                        path="/lk/worker/crm/:id/services/:serviceID"
                        element={<ServiceProfile />}
                    />
                    <Route path="/lk/worker/crm/:id/services/create" element={<ServiceProfile />} />
                    <Route
                        path="/lk/worker/crm/:id/services/:serviceID/edit"
                        element={<ServiceProfile />}
                    />
                    <Route
                        path="/lk/worker/crm/:id/task-categories"
                        element={<TaskCategoryList />}
                    />
                    <Route
                        path="/lk/worker/crm/:id/task-categories/:taskCategoryID"
                        element={<TaskCategoryProfile useCase={TaskUseCases.Profile} />}
                    />
                    <Route
                        path="/lk/worker/crm/:id/task-categories/create"
                        element={<TaskCategoryProfile useCase={TaskUseCases.Create} />}
                    />
                    <Route
                        path="/lk/worker/crm/:id/task-categories/:taskCategoryID/edit"
                        element={<TaskCategoryProfile useCase={TaskUseCases.Edit} />}
                    />
                    <Route path="/lk/worker/crm/:id/tasks" element={<TaskList />} />
                    <Route
                        path="/lk/worker/crm/:id/tasks/nav/:taskCategoryId"
                        element={<TaskList />}
                    />
                    <Route
                        path="/lk/worker/crm/:id/tasks/create"
                        element={<TaskProfile useCase={TaskUseCases.Create} />}
                    />
                    <Route
                        path="/lk/worker/crm/:id/tasks/:taskID"
                        element={<TaskProfile useCase={TaskUseCases.Profile} />}
                    />
                    <Route
                        path="/lk/worker/crm/:id/tasks/:taskID/edit"
                        element={<TaskProfile useCase={TaskUseCases.Edit} />}
                    />
                    <Route path="/lk/worker/crm/:id/orders" element={<OrderList />} />
                    <Route path="/lk/worker/crm/:id/orders/create" element={<OrderCreate />} />
                    <Route path="/lk/worker/crm/:id/orders/:orderID" element={<OrderProfile />} />
                    {/** Временное решение для демонстрации изменений URI */}
                    <Route
                        path="/lk/worker/crm/:id/orders/:orderID/:worksheetName"
                        element={<OrderProfile />}
                    />
                    {/** */}
                    {/*<Route path="/lk/worker/crm/:id/orders/:orderID/edit" element={<OrderEdit />} />*/}
                    <Route path="/lk/worker/crm/:id/logs" element={<LogList />} />
                    <Route path="/lk/worker/crm/:id/transactions" element={<TransactionList />} />
                    <Route path="/lk/worker/crm/:id/settings" element={<Settings />} />
                    <Route
                        path="/lk/worker/crm/:id/settings/:sectionName"
                        element={<CustomizableSection />}
                    />
                    <Route
                        path="/lk/worker/crm/:id/settings/:sectionName/worksheets"
                        element={<Worksheets />}
                    />
                    <Route
                        path="/lk/worker/crm/:id/settings/:sectionName/worksheets/:worksheetId"
                        element={<WorksheetSetting />}
                    />
                    <Route 
                        path="/lk/worker/crm/:id/settings/:sectionName/statuses"
                        element={<OrderStatusSetting />}
                    />
                    <Route
                        path="/lk/worker/crm/:id/settings/:sectionName/styles"
                        element={<StyleSetting />}
                    />

                    <Route path="/lk/admin" element={<Administrator />} />
                    <Route path="/lk/admin/:sectionCode" element={<Section />} />
                </Routes>
            </Suspense>
        );
    }

    return (
        <div>
            <ApelsinHeader />
            <div id="apelsin-app-content" style={curStyles}>{getBody()}</div>
            <ApelsinFooter />
        </div>
    );
});

export { App };
