import { CRMAPIBase } from "@api/crmApiBase";
import { RequestResult } from "@api/responseModels/requestResult";
import { TaskListResp } from "@api/responseModels/task/taskListResponse";
import { TaskResp } from "@api/responseModels/task/taskResponse";
import { TTask } from "@components/tasks/common/types";
import { TaskParams } from "types/getParams";

class TaskGroup extends CRMAPIBase {
    /**
     * Добавление задачи
     * @param task Задача
     * @returns Результат запроса
     */
    async saveTask(task: TTask): Promise<RequestResult<TaskResp>> {
        const result = await this.post<TaskResp>('/tasks', { ...task });
        return result;
    }

    /**
     * Получение задачи
     * @param crmId ID CRM
     * @param id ID задачи
     * @returns Результат запроса
     */
    async getTask(crmId: number, id: number): Promise<RequestResult<TaskResp>> {
        const result = await this.get<TaskResp>(`/tasks/${id}`, { crm_id: crmId });
        return result;
    }

    /**
     * Получение списка задач
     * @param params Параметры запроса задач
     * @returns Результат запроса
     */
    async getTaskList(params: TaskParams): Promise<RequestResult<TaskListResp>> {
        const result = await this.get<TaskListResp>('/tasks', {
            ...params,
        });
        return result;
    }

    /**
     * Обновление задачи
     * @param task Задача
     * @returns Результат запроса
     */
    async updateTask(task: TTask): Promise<RequestResult<TaskResp>> {
        const result = await this.put<TaskResp>(`/tasks/${task.id}`, { ...task });
        return result;
    }

    /**
     * Удаление задачи
     * @param id ID задачи
     * @param crmId ID CRM
     * @returns Результат запроса
     */
    async removeTask(id: number, crmId: number): Promise<RequestResult<TaskResp>> {
        const result = await this.delete<TaskResp>(`/tasks/${id}`, { crm_id: crmId });
        return result;
    }

    /**
     * Восстановление задачи
     * @param id ID задачи
     * @param crmId ID CRM
     * @returns Результат запроса
     */
    async restoreTask(id: number, crmId: number): Promise<RequestResult<TaskResp>> {
        const result = await this.post<TaskResp>(`/tasks/${id}/restore`, { crm_id: crmId });
        return result;
    }

    /**
     * Обновление категорий задач
     * @param crmId ID CRM
     * @param tasks Карта перемещений
     * @returns Результат запроса
     */
    async updateTaskCategories(
        crmId: number,
        tasks: Array<{ id: number; task_category_id: number }>
    ): Promise<RequestResult<TaskListResp>> {
        const result = await this.post<TaskListResp>('/tasks/categories', {
            crm_id: crmId,
            tasks: tasks,
        });
        return result;
    }
}
    
export { TaskGroup };