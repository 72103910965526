import { CRMAPIBase } from "@api/crmApiBase";
import { RequestResult } from "@api/responseModels/requestResult";
import { TaskCategoryListResp } from "@api/responseModels/task/taskCategoryListResponse";
import { TaskCategoryResp } from "@api/responseModels/task/taskCategoryResponse";
import { CategoryParams } from "types/getParams";

class TaskCategoryGroup extends CRMAPIBase {
    /**
     * Добавление категории задач
     * @param name Название категории
     * @param crmId ID CRM
     * @param picture Фото категории
     * @returns Результат запроса
     */
    async saveTaskCategory(
        name: string,
        crmId: number,
        parentCatId?: number,
        picture?: File
    ): Promise<RequestResult<TaskCategoryResp>> {
        const result = await this.post<TaskCategoryResp>('/task-categories', {
            name, 
            crm_id: crmId, 
            parent_task_category_id: parentCatId, 
            picture
        });
        return result;
    }

    /**
     * Получение категории задач
     * @param categoryId ID Категории
     * @param crmId ID CRM
     * @returns Результат запроса
     */
    async getTaskCategory(
        crmId: number,
        categoryId?: number,
    ): Promise<RequestResult<TaskCategoryResp>> {
        const result = await this.get<TaskCategoryResp>(`/task-categories/${categoryId}`, {
            crm_id: crmId,
        });
        return result;
    }

    /**
     * Получение списка категорий задач
     * @param params Параметры списка
     * @returns Результат запроса
     */
    async getTaskCategoryList(params: CategoryParams): Promise<RequestResult<TaskCategoryListResp>> {
        const result = await this.get<TaskCategoryListResp>('/task-categories', {
            ...params
        });
        return result;
    }

    /**
     * Обновление категории задач
     * @param categoryId ID Категории
     * @param name Название категории
     * @param crmId ID CRM
     * @param picture Фото категории
     * @returns Результат запроса
     */
    async updateTaskCategory(
        name: string,
        crmId: number,
        parentTaskCategoryId: number,
        categoryId?: number,
        picture?: File
    ): Promise<RequestResult<TaskCategoryResp>> {
        const result = await this.put<TaskCategoryResp>(`/task-categories/${categoryId}`, {
            name: name,
            crm_id: crmId,
            picture: picture,
            parent_task_category_id: parentTaskCategoryId
        });
        return result;
    }

    /**
     * Удаление категории задач
     * @param categoryId ID Категории
     * @param crmId ID CRM
     * @returns Результат запроса
     */
    async removeTaskCategory(
        crmId: number,
        categoryId?: number,
    ): Promise<RequestResult<TaskCategoryResp>> {
        const result = await this.delete<TaskCategoryResp>(`/task-categories/${categoryId}`, {
            crm_id: crmId,
        });
        return result;
    }

    /**
     * Восстановление категории задач
     * @param categoryId ID Категории
     * @param crmId ID CRM
     * @returns Результат запроса
     */
    async restoreTaskCategory(
        crmId: number,
        categoryId: number,
    ): Promise<RequestResult<TaskCategoryResp>> {
        const result = await this.post<TaskCategoryResp>(`/task-categories/${categoryId}/restore`, {
            crm_id: crmId,
        });
        return result;
    }

    /**
     * Обновление родительских категорий
     * @param crmId ID CRM
     * @param taskCategories Карта перемещений
     * @returns Результат запроса
     */
    async updateTaskParentCategories(
        crmId: number,
        taskCategories: Array<{ id: number; parent_task_category_id: number }>
    ): Promise<RequestResult<any>> {
        const result = await this.post<any>('/task-categories/update/bulk', {
            crm_id: crmId,
            task_categories: taskCategories,
        });
        return result;
    }

    /**
     * Получение id всех дочерних категорий, включая потомки потомков
     * @param crmId ID CRM
     * @param categoryId id категории
     * @returns Результат запроса
     */
    async getAllIncludedTaskCategories(
        crmId: number,
        categoryId?: number
    ): Promise<RequestResult<any>> {
        const result = await this.get<any>(`/task-categories/${categoryId}/categories`, {
            crm_id: crmId,
        });
        return result;
    }
}

export { TaskCategoryGroup };