import { ChatMessagesParams, CreateChatMessageParams } from 'types/getParams';

import { CRMAPIBase } from '@api/crmApiBase';
import { ChatMessageResp } from '@api/responseModels/chatMessages/chatMessageResponse';
import { ChatMessagesResp } from '@api/responseModels/chatMessages/chatMessagesResponse';
import { RequestResult } from '@api/responseModels/requestResult';

class ChatMessagesGroup extends CRMAPIBase {
    async getChatMessages(params: ChatMessagesParams): Promise<RequestResult<ChatMessagesResp>> {
        const response = await this.get<ChatMessagesResp>('/chat-messages', params);
        return response;
    }

    async createChatMessage(
        params: CreateChatMessageParams
    ): Promise<RequestResult<ChatMessageResp>> {
        const response = await this.post<ChatMessageResp>(`/chat-messages`, params);
        return response;
    }

    async updateChatMessage(
        crm_id,
        worker_position_id,
        chat_id,
        message
    ): Promise<RequestResult<ChatMessageResp>> {
        const response = await this.put<ChatMessageResp>(`/chat-messages/${chat_id}`, {
            crm_id,
            worker_position_id,
            message,
        });
        return response;
    }

    async deleteChatMessage(
        crm_id,
        worker_position_id,
        messageId
    ): Promise<RequestResult<ChatMessageResp>> {
        const response = await this.delete<ChatMessageResp>(`/chat-messages/${messageId}`, {
            crm_id,
            worker_position_id,
        });
        return response;
    }
}

export { ChatMessagesGroup };
