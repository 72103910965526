import { useLocation, useNavigate } from 'react-router-dom';

import { MenuProps, Tooltip } from 'antd';
import { observer } from 'mobx-react';

import { Permissions } from '@constants/permissions';
import {
    faArrowRightFromBracket,
    faBarcode,
    faBookOpen,
    faBriefcase,
    faDollar,
    faGear,
    faHouse,
    faPersonRunning,
    faPlug,
    faScrewdriverWrench,
    faTableList,
    faUserTie,
    faUsers,
    faUsersGear,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppFooter from '@molecules/appFooter/appFooter';
import { TAB_ROUTES } from '@organisms/workers/common/constants';
import { LastIdStore } from '@pages/lastIdStore';

import { SettingsManager } from '../../../classes/settingsManager';
import { SecurityStore } from '@organisms/security/securityStore';
import { LoginAsOptions } from '@enums/loginAsOptions';
import { rootStore } from '@store/rootStore/instanse';
import { TUser } from 'types/user/user';
import { GlobalConstants } from '@constants/global';

const ApelsinFooter = observer((): JSX.Element => {
    const navigate = useNavigate();
    const location = useLocation();
    const creds = SettingsManager.getConnectionCredentials();

    const _unconnectedItems: MenuProps['items'] = [
        {
            icon: (
                <Tooltip placement="topRight" title="Подключение">
                    <FontAwesomeIcon icon={faPlug} />
                </Tooltip>
            ),
            key: 'login',
            title: 'Подключение',
            onClick: () => navigate('/login'),
        },
        {
            disabled: !creds?.token,
            icon: <FontAwesomeIcon icon={faUserTie} />,
            key: 'personal-profile',
            onClick: () => navigate('/lk'),
            title: 'Профиль',
        },
        {
            disabled: !creds?.token,
            icon: <FontAwesomeIcon icon={faGear} />,
            key: 'personal-settings',
            onClick: () => navigate(`/lk/worker/crm/${creds.crmID}/settings`),
            title: 'Настройки',
        },
        {
            disabled: !creds?.token,
            icon: <FontAwesomeIcon icon={faArrowRightFromBracket} />,
            key: 'personal-logout',
            onClick: () => navigate('/logout'),
            title: 'Закрыть сессию',
        },
    ];

    const _navLinks: MenuProps['items'] = [
        {
            disabled: !creds?.crmID || Permissions.checkPermission(Permissions.CrmTotal),
            icon: <FontAwesomeIcon icon={faHouse} />,
            key: 'crm',
            onClick: () => navigate(`/lk/worker/crm/${creds.crmID}`),
            title: 'Профиль CRM',
        },
        {
            disabled: !creds?.crmID || Permissions.checkPermission(Permissions.WorkerPositionList),
            icon: <FontAwesomeIcon icon={faUsersGear} />,
            key: 'workers',
            onClick: () => {
                const lastPath = rootStore.navigationStore.getLastPath('workers');
                if (lastPath == null || lastPath == location.pathname) {
                    navigate(`/lk/worker/crm/${creds.crmID}/workers/tabs/${TAB_ROUTES.WORKING_NOW}`);
                } else {
                    navigate(lastPath);
                }
            },
            title: 'Сотрудники',
        },
        {
            disabled: !creds?.crmID || Permissions.checkPermission(Permissions.CustomerProfileList),
            icon: <FontAwesomeIcon icon={faUsers} />,
            key: 'customers',
            onClick: () => {
                const lastPath = rootStore.navigationStore.getLastPath('customers');
                if (lastPath == null || lastPath == location.pathname) {
                    navigate(`/lk/worker/crm/${creds.crmID}/customers`);
                } else {
                    navigate(lastPath);
                }
            },
            title: 'Клиенты',
        },
        {
            disabled: !creds?.crmID || Permissions.checkPermission(Permissions.OrderList),
            icon: <FontAwesomeIcon icon={faTableList} />,
            key: 'orders',
            onClick: () => {
                const lastPath = rootStore.navigationStore.getLastPath('orders');
                if (lastPath == null || lastPath == location.pathname) {
                    navigate(`/lk/worker/crm/${creds.crmID}/orders`);
                } else {
                    navigate(lastPath);
                }
            },
            title: 'Заказы',
        },
        {
            disabled: !creds?.crmID,
            icon: <FontAwesomeIcon icon={faPersonRunning} />,
            key: 'tasks',
            onClick: () => {
                const lastPath = rootStore.navigationStore.getLastPath('tasks');
                if (lastPath == null || lastPath == location.pathname) {
                    navigate(`/lk/worker/crm/${creds.crmID}/tasks`);
                } else {
                    navigate(lastPath);
                }
            },
            title: 'Задачи',
        },
        {
            disabled: !creds?.crmID || Permissions.checkPermission(Permissions.ProductList),
            icon: <FontAwesomeIcon icon={faBarcode} />,
            key: 'products',
            onClick: () => {
                const lastPath = rootStore.navigationStore.getLastPath('products');
                if (lastPath == null || lastPath == location.pathname) {
                    navigate(`/lk/worker/crm/${creds.crmID}/products`);
                } else {
                    navigate(lastPath);
                }
            },
            title: 'Товары',
        },
        {
            disabled: !creds?.crmID || Permissions.checkPermission(Permissions.ServiceList),
            icon: <FontAwesomeIcon icon={faScrewdriverWrench} />,
            key: 'services',
            onClick: () => {
                const lastPath = rootStore.navigationStore.getLastPath('services');
                if (lastPath == null || lastPath == location.pathname) {
                    navigate(`/lk/worker/crm/${creds.crmID}/services`);
                } else {
                    navigate(lastPath);
                }
            },
            title: 'Услуги',
        },
        {
            disabled: !creds?.crmID || Permissions.checkPermission(Permissions.TransactionList),
            icon: <FontAwesomeIcon icon={faDollar} />,
            key: 'transactions',
            onClick: () => navigate(`/lk/worker/crm/${creds.crmID}/transactions`),
            title: 'Транзакции',
        },
        {
            disabled: !creds?.crmID || Permissions.checkPermission(Permissions.LogList),
            icon: <FontAwesomeIcon icon={faBookOpen} />,
            key: 'logs',
            onClick: () => navigate(`/lk/worker/crm/${creds.crmID}/logs`),
            title: 'Журнал',
        },
        {
            disabled: creds?.token == null,
            icon: <FontAwesomeIcon icon={faBriefcase} />,
            key: 'workplace',
            onClick: () => {
                LastIdStore.setLastUserId(null);
                if (location.pathname.includes('crm')) {
                    navigate(`/lk/worker/crm/${creds.crmID}/users/${creds.userId}`);
                } else {
                    navigate(`/lk/worker/profile`);
                }
            },
            title: 'Рабочее место',
        },
        {
            key: 'personal-divider',
        },
        {
            disabled: !creds?.token || Permissions.checkPermission(Permissions.CrmList),
            icon: <img 
                style={{
                    borderRadius: "50%",
                    maxHeight: "100%"
                }} 
                src={GlobalConstants.BaseUrlForImg + ((rootStore.currentUserStore.user as TUser)?.picture?.toString() ?? '/images/users/no_image_worker.svg')} 
            />,//<FontAwesomeIcon icon={faUserTie} />,
            key: 'personal-profile',
            onClick: () => navigate('/lk'),
            title: 'Профиль',
        },
        /*{
            disabled: !creds?.token || Permissions.checkPermission(Permissions.CrmList),
            icon: <FontAwesomeIcon icon={faGear} />,
            key: 'personal-settings',
            onClick: () => navigate(`/lk/worker/crm/${creds.crmID}/settings`),
            title: 'Настройки',
        },
        {
            icon: <FontAwesomeIcon icon={faArrowRightFromBracket} />,
            key: 'personal-logout',
            onClick: () => navigate('/logout'),
            title: 'Выйти',
        },*/
    ];

    const _adminNavLinks = [
        {
            disabled: !creds?.token || Permissions.checkPermission(Permissions.CrmList),
            icon: <FontAwesomeIcon icon={faUserTie} />,
            key: 'personal-profile',
            onClick: () => {
                SecurityStore.setLoginAs(LoginAsOptions.Employee)
                navigate('/lk')
            },
            title: 'Профиль',
        },
        {
            icon: <FontAwesomeIcon icon={faArrowRightFromBracket} />,
            key: 'personal-logout',
            onClick: () => navigate('/logout'),
            title: 'Выйти',
        },
    ];

    return (
        <AppFooter
            items={
                creds?.token 
                    ? SecurityStore.loginAs === 'admin'
                        ? _adminNavLinks
                        : creds?.crmID 
                            ? _navLinks
                            : _unconnectedItems
                    : _unconnectedItems
            }
            mosesSpread={
                creds?.token 
                    ? SecurityStore.loginAs === 'admin'
                        ? false
                        : creds?.crmID 
                            ? true
                            : false
                    : false
            }
        />
    );
});

export { ApelsinFooter };
